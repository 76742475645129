import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Burger from '../Common/Burger';

const Header = styled.header`
  left: 0;
  margin: 0 auto 50px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
  height: ${({ isOpen }) => (isOpen ? '100vh' : 'auto')};

  @media only screen and (min-width: 640px) {
    height: auto;
  }

  &::before {
    background: #3c1638;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      -moz-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      /* background: rgb(32 10 29 / 82%); */
      background: rgb(60 22 56 / 82%);
    }
  }

  .row {
    align-items: center;
    border-bottom: 1px solid rgba(199,87,132,0.25);
    box-shadow: 0 5px 0px 0px rgb(0 0 0 / 5%);
    height: ${({ isOpen }) => (isOpen ? '100vh' : 'auto')};
    position: relative;
    padding: 10px 20px;

    @media only screen and (min-width: 640px) {
      display: flex;
      flex-wrap: wrap;
      height: auto;
      justify-content: space-between;
      padding: 0 20px;
    }
  }

  .header__contact {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    position: absolute;
    right: 20px;
    top: 17px;

    @media only screen and (min-width: 1200px) { 
      top: 26px;
    }
  }
`;

const NavigationWrapper = styled.nav`
  width: 100%;

  img {
    vertical-align: middle;
  }

  @media only screen and (min-width: 640px) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;

const NavigationList = styled.ul`
  list-style: none;
  padding: 0;

  a {
    text-decoration: none;
  }

  ul {
    a {
      &.active {
        color: white;
        pointer-events: none;
      }
    }
  }

  @media only screen and (max-width: 639px) {
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    margin: 4rem 0;

    a {
      display: block;
      font-size: 3rem;
    }

    ul {
      list-style: none;
      margin: 3rem 0 4rem;
      padding: 0 0 0 3rem;

      li {
        margin: 0 0 1.5rem;
      }

      a {
        font-size: 1.8rem;
      }
    }
  }

  @media only screen and (min-width: 640px) {
    display: flex;
    height: 70px;

    > li > a {
      line-height: 70px;
    }
  }

  @media only screen and (min-width: 1200px) {
    height: 90px;

    > li > a {
      line-height: 90px;
    }
  }
`;

const NavigationListItem = styled.li`
  @media only screen and (max-width: 639px) {
    margin-bottom: 3rem;
  }

  @media only screen and (min-width: 640px) {
    padding-left: 3rem;

    &.submenu {
      position: relative;

      &:hover {
        ul {
          display: block;
        }
      }

      > a {
        padding-right: 20px;
        position: relative;

        &::after {
          content: '';
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #5dd882;
          position: absolute;
          right: 0;
          top: calc(50% - 1px);
        }
      }

      ul {
        display: none;
        border: 1px solid rgba(199,87,132,0.25);
        border-radius: 0 0 3px 3px;
        border-top: 0;
        font-size: 1.6rem;
        left: 10px;
        list-style: none;
        padding: 10px 15px 5px;
        position: absolute;
        top: 100%;
        width: 220px;

        &::before {
          background: #3c1638;
          content: '';
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;

          @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
            -moz-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
            background: rgb(60 22 56 / 82%);
          }
        }

        li {
          border-top: 1px solid rgba(199,87,132,0.25);
          padding: 6px 0 8px;
          position: relative;
          z-index: 1;

          &:first-child {
            border-top: 0;
            padding-top: 0;
          }
        }

        a {
          display: block;
          padding: 0 5px;
        }
      }
    }
  }
`;

const NrPhone = styled.p`
  display: none;
  font-size: 1.6rem;
  margin: 0 20px 0 0;
  vertical-align: middle;

  @media only screen and (min-width: 768px) {
    display: inline-block;
    font-size: 1.6rem;
    margin: 0 35px 0 0;
  }

  a {
    font-weight: 900;
    text-decoration: none;
    font-size: 2.5rem;
    margin: 0 5px;
  }
`;

export const HeaderComponent = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavigation = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Header isOpen={isOpen}>
      <div className="row">
        <NavigationWrapper>
          <Link to="/" title="Monety Olsztyn">
            <img src="/logo.svg" width="50" height="50" alt="Skup monet Olsztyn" />
          </Link>
          <NavigationList isOpen={isOpen}>
            <NavigationListItem className="submenu">
              <Link
                activeClassName="active"
                to="/skup-monet-i-banknotow/"
                onClick={toggleNavigation}
              >
                Skup
              </Link>

              <ul>
                <li>
                  <Link
                    activeClassName="active"
                    to="/skup-starych-banknotow/"
                    onClick={toggleNavigation}
                  >
                    Skup banknotów
                  </Link>
                </li>
                <li>
                  <Link
                    activeClassName="active"
                    to="/skup-monet-olsztyn/"
                    onClick={toggleNavigation}
                  >
                    Skup monet
                  </Link>
                </li>
                <li>
                  <Link
                    activeClassName="active"
                    to="/skup-medali-orderow-odznaczen/"
                    onClick={toggleNavigation}
                  >
                    Medale i odznaczenia
                  </Link>
                </li>
                <li>
                  <Link
                    activeClassName="active"
                    to="/stare-pocztowki/"
                    onClick={toggleNavigation}
                  >
                    Stare pocztówki
                  </Link>
                </li>
              </ul>
            </NavigationListItem>
            <NavigationListItem>
              <Link
                activeClassName="active"
                to="/wycena-monet-i-banknotow/"
                onClick={toggleNavigation}
              >
                Wycena
              </Link>
            </NavigationListItem>
            {/* <NavigationListItem>
              <Link
                activeClassName="active"
                to="/blog/"
                onClick={toggleNavigation}
              >
                Blog
              </Link>
            </NavigationListItem> */}
            <NavigationListItem>
              <Link
                activeClassName="active"
                to="/kontakt/"
                onClick={toggleNavigation}
              >
                Kontakt
              </Link>
            </NavigationListItem>
          </NavigationList>
        </NavigationWrapper>

        <div className="header__contact">
          <NrPhone>
            tel:
            {' '}
            <a href="tel:507506476" itemProp="telephone">507 506 476</a>
          </NrPhone>

          <a href="https://www.facebook.com/skup.monet.olsztyn" rel="nofollow" title="Odwiedź naszego Facebooka" className="link-fb">
            <img
              src="/fb.svg"
              width="35"
              height="35"
              alt="facebook"
              style={{
                verticalAlign: 'middle',
              }}
            />
          </a>

          <Burger isOpen={isOpen} setIsOpen={setIsOpen} title="Menu" />
        </div>
      </div>
    </Header>
  );
};

export default HeaderComponent;
