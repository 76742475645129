import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-size: 62.5%;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  html,
  body {
    height: 100%;
    margin: 0;
  }
  body {
    background: #3c1638;
    color: #fff;
    font-family: 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.6rem;
    line-height: 160%;
    padding: 75px 0 0;

    @media only screen and (min-width: 480px) {
      padding: 95px 0 0;
    }

    @media only screen and (min-width: 640px) {
      font-size: 1.8rem;
      line-height: 150%;
    }

    @media only screen and (min-width: 1600px) {
      font-size: 2rem;
      line-height: 160%;
    }

    &.theme-black {
      background: black;
      padding-top: 0;

      main {
        min-height: auto;

        @media only screen and (min-width: 1024px) {
          height: 100vh;
          display: flex;
          align-items: center;
        }
      }

      .row {
        padding: 0;
      }

      .notFound__content {
        padding: 20px;
      }

      header,
      footer {
        display: none;
      }
    }
  }
  button {
    padding: 0;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
  }
  p {
    line-height: inherit;
    margin: 0 0 2rem;
  }
  iframe {
    background: #2e1b2b;
    margin: 45px -20px 0;
    width: calc(100% + 40px);

    @media only screen and (min-width: 1200px) {
      margin: 45px 0 0;
      width: 100%;
    }
  }
  dt,dd {
    margin: 0;
  }

  h1,h2,h3 {
    /* color: #5dd882; */
    color: #fff;
    line-height: 130%;

    a {
      text-decoration: none;
    }
  }

  h1 {
    margin-block-start: 0.67em;

    span {
      display: block;
    }
  }

  h1,h2 {
    font-size: 2.6rem;
    margin-bottom: 1.8rem;
    /* text-align: center; */
  }

  h2 {
    margin-top: 5rem;
  }

  h3 {
    font-size: 2rem;
    margin-bottom: 12px;
    margin-top: 5rem;
  }

  ol.list-decimal {
    counter-reset: counter;
    list-style: none;
    margin: 0 0 50px;

    li {
      counter-increment: counter;

      h3 {
        position: relative;

        &::before {
          color: #c75784;
          content: counter(counter) ".";
          font-weight: 600;
          left: -35px;
          position: absolute;
          top: 0;
        }
      }
    }
  }

  @media only screen and (min-width: 480px) {
    h2 {
      margin: 0;
      overflow: hidden;
      padding: 6rem 0 3rem;

      span {
        position: relative;

        &::before,
        &::after {
          content: '';
          border-top: 1px solid rgba(199,87,132,0.25);
          position: absolute;
          top: calc(50% + 4px);
          width: 300%;
        }

        &::before {
          right: calc(100% + 20px);
        }

        &::after {
          left: calc(100% + 20px);
        }
      }
    }
  }

  @media only screen and (min-width: 800px) {
    h1 {
      font-size: 3.6rem;
      line-height: 120%;
      margin-bottom: 3rem;
      text-align: left;
    }

    h2 {
      font-size: 3rem;
    }

    h3 {
      font-size: 2.2rem;
    }
  }
  ul {
    margin: 0;
  }
  a {
    color: #5dd882;
    cursor: pointer;
    /* text-decoration: underline; */
    transition: color 200ms ease-in, border-color 200ms ease-in;

    &:hover,
    &:focus {
      color: #fff;
    }

    &:focus {
      background: rgba(199,87,132,0.3);
      outline: 1px dotted #c75784;
    }
  }
  cite {
    border-left: 3px solid #c75784;
    background: rgba(199,87,132,0.1);
    font-style: italic;
    max-width: 640px;
    border-radius: 3px;
    display: block;
    margin: 50px 0;
    padding: 15px 20px 20px 25px;
    line-height: 140%;

    .author {
      font-style: normal;
      display: block;
      text-align: right;
      margin: 30px 0 0;
      font-size: 1.6rem;
    }
  }
  figure {
    margin: 50px 0;
  }
  figcaption {
    font-style: italic;
    margin: 15px 0 0;
    text-align: center;
  }
  img {
    height: auto;
    max-width: 100%;
  }
  .row {
    margin-inline: auto;
    width: min(100%, 1200px);
    padding: 10px 15px;

    @media only screen and (min-width: 640px) {
      padding: 20px;
    }
  }
  sup {
    line-height: 0;
    font-size: 1.4rem;
    margin: 0;
    position: relative;
    top: 2px;
  }
  .btn {
    transition: background 200ms ease-in, color 200ms ease-in;
    background: #5dd882;
    border: 0;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
    padding: 7px 25px 9px;
    text-decoration: none;

    &:hover {
      background: #49ed7a;
      color: #fff;
    }

    &:focus {
      color: #fff;
      background: none;
    }
  }

  .text-center {
    text-align: center;
  }

  /* .no-marg {
    margin: 0;
  } */

  .btn--white {
    transition: opacity 150ms ease-in;
    background: #fff;
    border-radius: 3px;
    /* box-shadow: 0 4px #5dd882; */
    color: #000;
    display: inline-block;
    font-size: 1.6rem;
    padding: 8px 24px;
    text-decoration: none;

    &:hover,
    &:focus {
      color: #000;
    }

    &:hover {
      opacity: .5;
    }

    &:focus {
      box-shadow: 0 0 0 4px #5dd882;
      background: #fff;
      outline: none;
      opacity: 1;
    }

    img {
      margin: 0 10px 0 0;
      position: relative;
      top: -2px;
      width: 24px;
      vertical-align: middle;
    }
  }

  .btn--outline {
    background: none;
    border: 1px solid #5dd882!important;
    border-radius: 4px;
    color: #5dd882;
    text-transform: none;

    &:hover {
      color: #1a0e18!important;
    }
  }

  .section-text {
    border-bottom: 1px solid rgba(199,87,132,0.25);
    max-width: 900px;
    padding: 0 0 45px;

    ul {
      margin-bottom: 5rem;
    }

    &--no-border {
      border: 0;
    }

    &:last-of-type,
    &--no-border {
      border-bottom: 0;
    }
  }

  .section-services {
    border-top: 1px solid rgba(199,87,132,0.25);
    margin-top: 45px;
  }

  .scroll-box {
    margin: 0 0 5rem;
    overflow-x: auto;
    scrollbar-color: #c75784 transparent;
  }

  table {
    border: 1px solid rgba(199, 87, 132, 0.25);
    border-spacing: 0;
    border-collapse: collapse;
    font-size: 1.6rem;
    margin: 0 0 1rem;
    max-width: 100%;
    min-width: 900px;
    width: 100%;

    sup {
      color: #f00;
      font-size: 2rem;
      position: relative;
      top: 6px;
    }

    th,td {
      border: 1px solid rgba(199, 87, 132, 0.25);
      text-align: left;
    }

    th {
      background: #340f2f;
      padding: 5px;
      text-align: center;
    }

    tbody {
      td {
        transition: background 100ms ease-in;
        height: 45px;
        padding: 0 5px;

        &:nth-child(1),
        &:nth-child(2) {
          text-align: right;
        }

        &:nth-child(6),
        &:nth-child(9) {
          text-align: center;
        }
      }

      tr {
        &:nth-child(even) {
          td {
            background: #361532;
          }
        }

        &:hover td {
          background: #45153f;
        }
      }
    }

    tfoot {
      td {
        font-size: 14px;
        padding: 10px 15px 10px 20px;
        text-align: left;
        
        p {
          margin: 0;
        }
      }
    }
  }
`;

export default GlobalStyle;
