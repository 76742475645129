module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-120},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"linkImagesToOriginal":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#200b1d","description":"Skup monet na terenie Olsztyna i okolic. Skup monet srebrnych, obiegowych, kolekcjonerskich, okres międzywojenny, II RP, darmowa profesjonalna wycena","display":"fullscreen","dir":"ltr","icon":"src/assets/images/favicon.png","icons":[{"src":"/favicons/homescreen48.png","sizes":"48x48","type":"image/png"},{"src":"/favicons/homescreen72.png","sizes":"72x72","type":"image/png"},{"src":"/favicons/homescreen96.png","sizes":"96x96","type":"image/png"},{"src":"/favicons/homescreen144.png","sizes":"144x144","type":"image/png"},{"src":"/favicons/homescreen168.png","sizes":"168x168","type":"image/png"},{"src":"/favicons/homescreen192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/splash-screen.png","sizes":"512x512","type":"image/png"}],"lang":"pl","name":"Skup monet Olsztyn","orientation":"portrait","short_name":"Skup monet","start_url":"/","theme_color":"#200b1d","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9688502383ada23a29031f7b403dd2a8"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
